import { useState, useContext } from 'react'
import { AuthContext, getEmail } from '../../contexts/auth';
import React from 'react';
import './login.css'
import { useNavigate } from 'react-router';
import logo from '../../icons/Component 2.png';


function Login(): React.JSX.Element {

  const login = getEmail();
  const [password, setPassword] = useState("");
  const { userLogin, sendRecoverPassword } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {

    let error: any;

    event.preventDefault();

    try {
      console.log(login, password)
      const token = await userLogin(login, password);
      if (token) {
        console.log("acesso liberado");
      }
    } catch (event) {
      console.error('Erro no login:', error.message);
      setErrorMessage(error.message);
    }

  };

  const handleRecoverPassword = async (event: any) => {

    let error: any;

    event.preventDefault();

    try {
      const response = await sendRecoverPassword(login);
      if (response === 200) {
        console.log("solicitação concluida");
        navigate("/RecoverPassword");
      }
    } catch (event) {
      console.error('Erro no login:', error.message);
      setErrorMessage(error.message);
    }

  }

  return (

    <div className="loginContainer">
      <form onSubmit={handleSubmit} className="formContainer" id='teste'>
        <div className="loginlogo">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div >
          <span className="loginTitle">Digite sua senha</span>
        </div>
        <div>
          {errorMessage && <div className="error">{errorMessage}</div>}
        </div>
        <div className='wrap'>
          <div className="wrapInput5">
            <input type="password" placeholder='Digite sua senha' className='input' value={password} onChange={e => setPassword(e.target.value)} required />
          </div>
        </div>
        <div className="containerLoginFormBtn">
          <button className='loginFormBtn' onClick={handleSubmit}>Continuar</button>
        </div>
        <div className="containerLoginFormBtn">
          <p>Esqueceu a sua senha? <button className='registerFormBtn' onClick={handleRecoverPassword}>Recuperar Senha</button></p>
        </div>
      </form>
    </div>
  )
}

export default Login;