import React from 'react';
import './ContractTelecomButton.css'
import { useNavigate } from 'react-router';

function ContractTelecomButtonCp(): JSX.Element {

    const navigate = useNavigate();

    function handleContacts() {
        navigate('/Contacts')
    }

    return (
        <>
            <div>
                <button onClick={handleContacts} className='contractTelecomButtonCp'>Contratar</button>
            </div>
        </>
    );
}

export default ContractTelecomButtonCp;