import React from 'react';
import AppRoutes from './AppRoutes';
import './App.css'

function App() {

  return (
    <AppRoutes />
  );
}

export default App;
