import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import faqs from "../../interfaces/faqs.json";
import './Faq.css'

interface FaqItem {
    id: number;
    title: string;
    description: string;
}


function Faq(): JSX.Element {

    const [expandedId, setExpandedId] = useState<number | null>(null);

    const toggleFaq = (id: number) => {
        setExpandedId((prevId) => (prevId === id ? null : id));
    };

    const renderDescription = (description: string) => {
        const lines = description.split('\n');
        return (
            <>
                {lines.map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </>
        );
    };

    const extractUrl = (description: string | any) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const matches = description.match(urlRegex);
        return matches ? matches[0] : null;
    };

    return (
        <div className="faqContainer">
            {faqs.result.map((item: FaqItem) => (
                <div key={item.id} className="faqItem">
                    <div>
                        <div className="faqQuestion" onClick={() => toggleFaq(item.id)}>
                            <div className='question'>
                                {item.title}
                            </div>
                            <div className='faqQuestionIcon'>
                                {expandedId === item.id ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>
                        {expandedId === item.id && (
                            <div className="faqAnswer">
                                {renderDescription(item.description)}
                                {item.description.includes('https://') && (
                                    <>
                                        <br />
                                        <a href={extractUrl(item.description)} target="_blank" rel="noopener noreferrer">Veja o tutorial</a>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        <span className='separadorHorizontal2'></span>
                    </div>
                </div>
            ))}

        </div>
    );
}

export default Faq;