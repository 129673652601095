import ContractAdditionalDataButtonCp from "./ContractAdditionalDataButtonCp";
import plans from "../../interfaces/internetPlans.json";
import React from 'react';
import './TelecomPlans.css'

function AdditionalDataPlans(): React.JSX.Element {

    return (
        <>
            {plans.internetPlans.map(item => (
                <div className="container">
                    <div className="planName">
                        <p className="planName">{item.planName}</p>
                    </div>
                    <div className="planGigas">
                        <p id="planGigas">{item.planGb}</p>
                    </div>
                    <div className="planBenefits">
                        {item.planBenefits.map((benefit, id) => (
                            <p key={id}>{benefit}</p>
                        ))}
                    </div>
                    <div>
                        <p className="planPrice">R${item.planValue}/mês</p>
                    </div>
                    <div className="contractButton">
                        <ContractAdditionalDataButtonCp />
                    </div>
                </div>
            ))}

        </>
    );
}

export default AdditionalDataPlans;