import React from 'react';
import logo from '../../icons/Component 2.png';
import facebook from '../../icons/facebook.png';
import illustration02 from '../../icons/image 4.png';
import { useNavigate } from 'react-router';
import "./site.css"


function Contacts(): React.JSX.Element {

  const navigate = useNavigate();

  const handleLogin = async (event: any) => {

    event.preventDefault();

    navigate("/EmBreve");

  };

  return (
    <>
      <main>
        <header>
          <div className='line01'>
            <div className='headerLogo'>
              <img src={logo} alt="logo" />
            </div>
            <div className='mainButtonsB'>
              <button onClick={handleLogin} className='loginButton01'>Registro/Login</button>
            </div>
          </div>

          <div className='headerButtons'>
            <div className='mainButtons'>
              <div className='mainButtonsA'>
                <a href="https://internetalfa.com.br/" className='homeButton'>Início</a>
                <a href="https://internetalfa.com.br/">Internet Alfa</a>
                <a href="https://internetalfa.com.br/">Alfa Celular</a>
                <a href="https://internetalfa.com.br/">FAQ</a>
                <a href="https://internetalfa.com.br/">Quem Somos</a>
                <a href="https://mk.internetalfa.com.br/sac/login/?sys=SAC">SAC</a>
              </div>
              <div className='mainButtonsB'>
                <button onClick={handleLogin} className='loginButton02'>Registro/Login</button>
              </div>

            </div>

          </div>

        </header>
        <body>
          <div className='contatosContainer'>
            <div className='contatosC1'>
              <div className='contatosTitle'>
                <span><p>Entre em contato</p></span>
              </div>
              <div className='contatosDescription'>
                <p>Quer assinar um plano de internet ou cadastrar o seu plano de telefonia? Entre em contato conosco!</p>
                <p>Nosso atendimento funciona em horário comercial de segunda à sexta-feira das 09:00 às 18:00 horas através dos seguintes canais:</p>
              </div>
              <div className='contatos02'>
                <div className='telefones'>
                  <span><p>Telefones:</p></span>
                  <p>(11) 3983-0314</p>
                  <p>(11) 3984-0896</p>
                  <p>0800 771 03148</p>
                </div>
                <div>
                  <div className='redes'>
                    <div className='emails'>
                      <span><p>E-mail:</p></span>
                      <a href="mailto:contato@internetalfa.com.br">contato@internetalfa.com.br</a>
                    </div>
                    <span><p>Redes Sociais:</p></span>
                    <div >
                      {/* <img src="#" alt="wpp-icon" />
                      <img src="" alt="ig-icon" /> */}
                      <a href="https://www.facebook.com/AlfaTechnologies" rel='noreferrer' target='_blank'><img id='fbLogo' src={facebook} alt="fb-icon" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='contatosC2'>
              <img id='contactsImg' src={illustration02} alt="contact-us-img" />
            </div>
          </div>
        </body>
        <footer>
          <div className='footer'>
            <div className='endereco' id='endereco'>
              <h1>ALFA NETWORK</h1>
              <p>RUA CARLOS SCHUMACKER Nº 314, SALA 01 - JARDIM DOS FRANCOS, SÃO PAULO, BRASIL</p>
              <p>CNPJ: 13.026.557/0001-43</p>
            </div>
          </div>
          <div className='termos'>
            <a href="/Contacts" id='termos'>Termos de Uso & Privacidade</a>
            <div className='copy' id='maincode'>
              <p>&copy; Copyright - All Rights Reserved By Alfa Networking | <a href="https://maincode.dev"> Powered By  MainCode</a></p>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}

export default Contacts;