import React from 'react';
import './ContractTelecomButton.css'
import { useNavigate } from 'react-router';

function ContractTelecomButton(): JSX.Element {

    const navigate = useNavigate();

    function handleContact (){

        navigate('/Contacts')

    }

    return (
        <>
            <div className='contractTelecomButton'>
                <button onClick={handleContact}>Quero ser Alfa</button>
            </div>
        </>
    );
}

export default ContractTelecomButton;