import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Site from './pages/site';
import EmBreve from './pages/site/emBreve';
import Contacts from './pages/site/contacts';
import EnterLogin from './pages/login/enterEmail';
import Login from './pages/login/enterPassword';
import Dashboard from './pages/dashboard';
import ConfirmToken from './pages/register/token/confirmToken'
import UserData from './pages/register/userData'
import Register from './pages/register/index';
import SendRecoverPassword from './pages/login/recover_password/notification/recoverPassword';
import { AuthProvider, AuthContext } from '../src/contexts/auth';
import { useContext } from 'react'
import { ServiceProvider } from './contexts/service';

interface ConsultProviderProps {
    children: React.ReactNode;
}

function AppRoutes() {
    const Private: React.FC<ConsultProviderProps> = ({ children }) => {
        const { authenticated } = useContext(AuthContext) as { authenticated: boolean };

        if (!authenticated) {
            return <Navigate to="/" />;
        }

        return <>{children}</>;
    };

    const PrivateLogin: React.FC<ConsultProviderProps> = ({ children }) => {
        const { login } = useContext(AuthContext) as { login: string };

        if (!login) {
            return <Navigate to="/" />;
        }

        return <>{children}</>;
    };

    const PrivateRegister: React.FC<ConsultProviderProps> = ({ children }) => {
        const { login } = useContext(AuthContext) as { login: string };

        if (!login) {
            return <Navigate to="/" />;
        }

        return <>{children}</>;
    };

    return (
        <Router>
            <AuthProvider>
                <ServiceProvider>
                    <Routes>
                        <Route path="/" element={<Site />} />
                        <Route path="/EmBreve" element={<EmBreve />} />
                        <Route path="/Contacts" element={<Contacts />} />
                        <Route path="/EnterEmail" element={<EnterLogin />} />
                        <Route path="/Login" element={<PrivateLogin><Login /></PrivateLogin>} />
                        <Route path="/ConfirmToken" element={<PrivateRegister><ConfirmToken /></PrivateRegister>} />
                        <Route path="/UserData" element={<PrivateRegister><UserData /></PrivateRegister>} />
                        <Route path="/Register" element={<PrivateRegister><Register /></PrivateRegister>} />
                        <Route path="/RecoverPassword" element={<PrivateLogin><SendRecoverPassword /></PrivateLogin>} />
                        <Route
                            path="/Dashboard"
                            element={
                                <Private>
                                    <Dashboard />
                                </Private>
                            }
                        />
                    </Routes>
                </ServiceProvider>
            </AuthProvider>
        </Router>
    );
}

export default AppRoutes;