import React from 'react';
import InternetPlans from "../../components/general/InternetPlans";
import TelecomPlans from "../../components/general/TelecomPlans";
import ContractInternetButton from "../../components/general/ContractInternetButton";
import ContractTelecomButton from "../../components/general/ContractTelecomButton";
import Faq from "../../components/general/Faq";
import logo from '../../icons/Component 2.png';
import facebook from '../../icons/facebook.png';
import illustration from '../../icons/illustration01.png';
import maincode from '../../icons/Icon_Cor.png'
import { useNavigate } from 'react-router';
import "./site.css"


function Site(): React.JSX.Element {

  const navigate = useNavigate();

  const handleLogin = async (event: any) => {

    event.preventDefault();

    navigate("/EmBreve");

  };

  return (
    <>
      <main>
        <header>
          <div className='line01'>
            <div className='headerLogo'>
              <img src={logo} alt="logo" />
            </div>
            <div className='mainButtonsB'>
              <button onClick={handleLogin} className='loginButton01'>Registro/Login</button>
            </div>
          </div>

          <div className='headerButtons'>
            <div className='mainButtons'>
              <div className='mainButtonsA'>
                <a href="https://internetalfa.com.br/" className='homeButton'>Início</a>
                <a href="#alfa-internet02">Internet Alfa</a>
                <a href="#alfa-telecom">Alfa Celular</a>
                <a href="#faq02">FAQ</a>
                <a href="#duvidas02">Quem Somos</a>
                <a href="https://mk.internetalfa.com.br/sac/login/?sys=SAC">SAC</a>
              </div>
              <div className='mainButtonsB'>
                <button onClick={handleLogin} className='loginButton02'>Registro/Login</button>
              </div>

            </div>

          </div>

        </header>
        <body>
          <div id="home">
            <div id="banner-01" className="banner01">
              <div>
                <div className='line02'>
                  <div>
                    <h1>Há 10 anos conectando <span id='line02'>você</span> ao <span id='line02'>futuro</span>, levando internet veloz e confiável</h1>
                  </div>
                  <span className="separadorVertical"></span>
                  <div className='planAds'>
                    <span id='planAds'>
                      <p id='adsGigas'>500Mega</p>
                      <span className="separadorHorizontal"></span>
                      <p>de <span>R$120,00</span> por apenas</p>
                      <p className='price'>R$ 90,00/mês</p>
                    </span>
                    <div className='line04'>
                      <div className='line05'>
                        <p>Confira as nossas ofertas de internet e telefonia para aproveitar muito com sua família e amigos!</p>
                      </div>
                      <div>
                        <ContractTelecomButton />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='line06'>
                  <div className='line03'>
                    <p>Confira as nossas ofertas de internet e telefonia para aproveitar muito com sua família e amigos!</p>
                  </div>
                  <div>
                    <ContractTelecomButton />
                  </div>
                </div>
              </div>
            </div>
            <div id='alfa-internet02'></div>
            <div id="alfa-internet" className='alfaInternet'>
              <div className='internetDescription'>
                <span className="separadorVertical"></span>
                <div>
                  <h2>Já pensou? A melhor internet banda larga com fibra ótica do Brasil na sua casa</h2>
                  <p>A sua internet de até 900 Mega para você aproveitar com a sua família e amigos, jogar, assistir streamings e até mesmo trabalhar com muita velocidade e estabilidade e tudo isso com nossa tecnologia de fibra ótica.</p>
                </div>
              </div>
              <div className='plansInternet'>
                <InternetPlans />
              </div>
              <div className='duvidas01'>
                <p>Tem alguma dúvida sobre nossos planos? Veja nosso <span><a href="#faq02">FAQ</a></span>, ou <span><a href="/Contacts">entre em contato conosco</a></span></p>
              </div>
            </div>
            <div id="banner-02" className='banner02'>
              <div id='bannerDescription02'>
                <div className='bannerDescription02'>
                  <h1>Tenha 900 Mega de internet com a nossa super oferta!</h1>
                  <p>Assine o noso Plano Internet Alfa e receba de bonus instalação gratuita, acesso à TV, filmes gratis e muito mais.</p>
                </div>
                <div>
                  <ContractInternetButton />
                </div>
              </div>
              <div className='planAds2'>
                <span id='planAds2'>
                  <p id='adsGigas2'>900Mega</p>
                  <span className="separadorHorizontal"></span>
                  <p>de <span>R$230,00</span> por apenas</p>
                  <p className='price2'>R$ 180,00/mês</p>
                </span>
              </div>
            </div>
            <div id="alfa-telecom" className='alfaTelecom'>
              <div className='telecomDescription'>
                <span className="separadorVertical"></span>
                <div>
                  <h2>Só falta você! Não perca a oportunidade de ter os planos que você sempre sonhou</h2>
                  <p>Tenha minutos e dados de sobra para conversar com seus amigos e familiares, usar aplicativos, e navegar na web com velocidade e estabilidade. Tudo isso, com nossos planos pós-pagos incríveis.</p>
                </div>
              </div>
              <div className='plansTelecom'>
                <TelecomPlans />
              </div>
              <div className='duvidas02' id='duvidas02'>
                <p>Tem alguma dúvida sobre nossos planos? Veja nosso <span><a href="#faq02">FAQ</a></span>, ou <span><a href="/Contacts">entre em contato conosco</a></span></p>
              </div>
            </div>
            <div id="about-us" className='aboutUs'>
              <div>
                <h2>Ok, mas por que eu devo escolher a Alfa?</h2>
                <p>Muito mais do que preço, aqui na Alfa nossa prioridade é entregar nosso melhor serviço para você e te atender da melhor forma possível para que você nunca mais precise se preocupar com contratar planos ruins de telefonia e internet.</p>
                <p>Estamos no mercado de telecomunicações a mais de 10 anos fazendo da experiência de navegação dos nossos clientes a melhor possível!</p>
                <p>Não abrimos mão da qualidade de nossos serviços. Estamos constantemente investindo em tecnologia e suporte para que você tenha uma melhor experiência conosco.</p>
              </div>
              <div>
                <img src={illustration} alt="about-us-img" />
              </div>
            </div>
            <div id='faq02'></div>
            <div className='faq'>
              <div className='faqTitle'>
                <h2>Perguntas Frequentes</h2>
                <span className="separadorHorizontal2"></span>
              </div>
              <div id='faq'>
                <Faq />
                {/* <span className="separadorHorizontal"></span> */}
              </div>
              <div className='duvidas03'>
                <p>Tem alguma dúvida sobre nossos planos? entre em contato conosco por <span><a href="/Contacts">aqui</a></span>!</p>
              </div>
            </div>
          </div>
        </body>
        <footer>
          <div className='footer'>
            <div className='endereco'>
              <h1>ALFA NETWORK</h1>
              <p>RUA CARLOS SCHUMACKER Nº 314, SALA 01 - JARDIM DOS FRANCOS, SÃO PAULO, BRASIL</p>
              <p>CNPJ: 13.026.557/0001-43</p>
            </div>
            <div>
              <span className="separadorVertical"></span>
            </div>
            <div className='contatos'>
              <div>
                <span><p>Entre em contato conosco</p></span>
              </div>
              <div className='contacts'>
                <div className='phones'>
                  <span><p>Telefones:</p></span>
                  <p>(11) 3983-0314</p>
                  <p>(11) 3984-0896</p>
                  <p>0800 771 03148</p>
                </div>
                <div>
                  <div>
                    <span><p>Redes Sociais:</p></span>
                    <div className='socialMedia'>
                      {/* <img src="#" alt="wpp-icon" />
                      <img src="" alt="ig-icon" /> */}
                      <a href="https://www.facebook.com/AlfaTechnologies" rel='noreferrer' target='_blank'><img src={facebook} alt="fb-icon" /></a>
                    </div>
                  </div>
                  <div className='email'>
                    <span><p>E-mail:</p></span>
                    <a href="mailto:contato@internetalfa.com.br">contato@internetalfa.com.br</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='termos'>
            <a href="/Contacts" id='termos'>Termos de Uso & Privacidade</a>
            <div className='copy' id='maincode'>
              <p>&copy; Copyright - All Rights Reserved By Alfa Networking | <a href="https://maincode.dev"> Powered By  MainCode</a></p>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}

export default Site;