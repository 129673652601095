import React, { useContext, useEffect, useState } from 'react';
import TelecomPlans from "../../components/general/TelecomPlans";
import Faq from "../../components/general/Faq";
import SeePlans from "../../components/general/SeePlans"
import logo from '../../icons/Component 2.png';
import facebook from '../../icons/facebook.png';
import maincode from '../../icons/Icon_Cor.png'
import { useNavigate } from 'react-router';
import { ServiceContext } from '../../contexts/service';
import AdditionalDataPlans from '../../components/general/AdditionalDataPlans';
import ChangePayentForm from '../../components/general/ChangePaymentForm';
import PayInvoice from '../../components/general/PayInvoice';
import "./dash.css"
import { Contract } from '../../interfaces/serviceInterfaces';

const mockResponse = {
  "success": true,
  "result": {
    "userName": "John Doe",
    "contracts": [
      {
        "contract": "12345",
        "phoneNumber": "12345678910",
        "mainContract": true,
        "statusContract": "bloqueado parcialmente",
        "plan": {
          "id": "",
          "name": "",
          "value": "",
          "nextCharge": "15/12/2023",
          "nextDuoDate": "15/12/2023"
        },
        "payments": {
          "chargeId": "",
          "invoiceAvailable": true,
          "chargeDate": "15/01/2024",
          "DuoDate": "15/12/2023",
          "planName": "",
          "value": "",
          "paymentForm": "",
          "lateInvoices": [
            {
              "chargeId": "",
              "chargeDate": "15/12/2023",
              "DuoDate": "15/12/2023",
              "planName": "",
              "value": ""
            }
          ]
        }
      },
      {
        "contract": "67890",
        "phoneNumber": "12996827680",
        "mainContract": false,
        "statusContract": "ativo",
        "plan": null,
        "payments": null
      }
    ]
  }
}

function Dashboard(): React.JSX.Element {

  const navigate = useNavigate();
  //const { consultContract, consultBalance, consultAdditionalDataPlans, consultPlans } = useContext(ServiceContext);

  const [invloices, setInvoices] = useState([]);
  const [lateInvoices, setLateInvoices] = useState([]);

  const [userData, setUserData] = useState(mockResponse.result);

  // Estado para armazenar o contrato selecionado
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

  // Efeito para carregar os dados do usuário e contratos ao montar o componente
  useEffect(() => {
    // Simulando a chamada à API consultContracts
    const fetchUserContracts = async () => {
      try {
        // Aqui você faria a chamada real à sua API
        // const response = await fetch('/api/consultContracts');
        // const data = await response.json();

        // Atualiza o estado com os dados obtidos
        // setUserData(data.result);

        // Encontra o contrato principal (mainContract: true)
        const mainContract = userData.contracts.find(contract => contract.mainContract === true);

        if (mainContract?.mainContract === true) {
          setSelectedContract(mainContract);
        }

      } catch (error) {
        console.error('Erro ao carregar dados do usuário:', error);
      }
    };

    // Chama a função para carregar os dados
    fetchUserContracts();
  }, []); // Dependência vazia para garantir que o efeito seja executado apenas uma vez ao montar o componente

  const handleSelectContract = (contract: any) => {
    console.log(contract)
    setSelectedContract(contract);
  };

  return (
    <>
      {selectedContract && (
        <main>
          <header>
            <div className=''>
              <div className=''>
                <img src={logo} alt="logo" />
              </div>
              <div className=''>
                <div>
                  <p>
                    Seja bem-vindo
                    {userData.contracts.length > 0 ? (
                      userData.contracts.map((user: any) => (
                        user.userName && user.userName.split(' ')[0].length < 10 ? (
                          <span>, {user.userName.split(' ')[0]}!</span>
                        ) : null
                      ))
                    ) : (
                      <span>!</span>
                    )}
                  </p>
                </div>
                <div>
                  <ul className="">
                    {userData.contracts.map((contract) => (
                      <li key={contract.contract} onClick={() => handleSelectContract(contract)}>
                        {contract.phoneNumber}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className=''>
              <div className=''>
                <a href="#alfa-internet">Planos</a>
                <a href="#alfa-telecom">Pagamento</a>
                <a href="#faq">Suporte</a>
                <a href="#about-us">Sair</a>
              </div>
            </div>
          </header>
          <body>
            <div id="">
              <div id="" className="">
                <div>
                  <div>
                    <h1>Seu Consumo</h1>
                    <p> Seu plano atual</p>
                    {userData.contracts.length > 0 ? (
                      selectedContract && selectedContract.plan && selectedContract.payments && (
                        <div className=''>
                          <div>
                            {selectedContract.plan.name}
                          </div>
                          <span className=''></span>
                          <div>
                            <div className=''>
                              <p>Próxima Cobrança:</p>
                              <p>Próximo Vencimento:</p>
                              <p>Forma de Pagamento:</p>
                            </div>
                            <div>
                              {selectedContract.plan.nextCharge}
                              {selectedContract.plan.nextDuoDate}
                              {selectedContract.payments.paymentForm}
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className=''>
                        <div className=''>
                          <p>Você ainda não escolheu um plano</p>
                        </div>
                        <span className=''></span>
                        <div>
                          <SeePlans />
                        </div>
                      </div>
                    )}
                    <p>Entenda seu consumo</p>
                  </div>
                  <div>
                    <p>Seu plano alfa te disponibiliza uma certa quantidade de gigas de dados móveis que ficam disponíveis para o consumo dentro de 30 dias.</p>
                    <p>Como benefício, nossos planos pemitem que você tenha ligações ilimitadas nesse período, assim como 30 envios gratuitos de SMS por dia.</p>
                    <p>Outro benefício que disponibilizamos é o WhatsApp ilimitado, mesmo que você tenha consumido todo o seu pacote de dados!</p>
                  </div>
                </div>
                <div><span className=''></span></div>
                {userData.contracts.length > 0 ? (
                  <div className=''>

                    <div>
                      <p>Dados Móveis</p>
                      <div>
                        <div>
                          <div>
                            {userData.contracts.length > 0 ? (
                              userData.contracts.map((contract: any) => (
                                <div>
                                  {/* {consultBalance()} */}
                                </div>
                              ))
                            ) : (
                              <SeePlans />
                            )}
                          </div>
                          <div>
                            {/* <div>aqui vem a barra de dados</div> */}
                          </div>
                        </div>
                        <div>
                          {userData.contracts.length > 0 ? (
                            userData.contracts.map((contract: any) => (
                              <p>aqui vem a quantidade de gigas</p>
                            ))
                          ) : (
                            <p>0.0Gb disponíveis</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>WhatsApp</p>
                      <div>
                        <div>
                          <div>
                            {userData.contracts.length > 0 ? (
                              <p>Ilimitado</p>
                            ) : (
                              <SeePlans />
                            )}
                          </div>
                          <div>
                            {/* <div>aqui vem a barra de dados</div> */}
                          </div>
                        </div>
                        <div>
                          <p>-</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>Ligações</p>
                      <div>
                        <div>
                          <div>
                            {userData.contracts.length > 0 ? (
                              <p>Ilimitado</p>
                            ) : (
                              <SeePlans />
                            )}
                          </div>
                          <div>
                            {/* <div>aqui vem a barra de dados</div> */}
                          </div>
                        </div>
                        <div>
                          <p>-</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>SMS</p>
                      <div>
                        <div>
                          <div>
                            {userData.contracts.length > 0 ? (
                              <p>30/dia</p>
                            ) : (
                              <SeePlans />
                            )}
                          </div>
                          <div>
                            {/* <div>aqui vem a barra de dados</div> */}
                          </div>
                        </div>
                        <div>
                          <p>-</p>
                        </div>
                      </div>
                    </div>
                    <p>Tem alguma dúvida sobre nossos planos? Veja nosso <span><a href="#">FAQ</a></span></p>
                  </div>
                ) : (
                  <div className=''></div>
                )}
                <div></div>
              </div>
              <div id="" className=''>
                <div className=''>
                  {userData.contracts.length > 0 ? (
                    <div className=''>
                      {/* {consultAdditionalDataPlans()} */}
                      <div>
                        <h2>Acabaram seus dados?</h2>
                        <p>Não se preocupe, você pode comprar dados adicionais sem ter que mudar de plano! Basta selecionar abaixo a quantidade de dados que deseja e efetuar o pagamento dos dados adicionais. Rápido e sem burocracia.</p>
                      </div>
                      <div><AdditionalDataPlans /></div>
                      <div className=''>
                        <p>Tem alguma dúvida sobre nossos planos? Veja nosso <span><a href="#">FAQ</a></span>, ou <span><a href="#">entre em contato conosco</a></span></p>
                      </div>
                    </div>
                  ) : (
                    <div className=''>
                      {/* {consultPlans()} */}
                      <div>
                        <h2>Nossos Planos</h2>
                        <p>Tenha minutos e dados de sobra para conversar com seus amigos e familiares, usar aplicativos, e navegar na web com velocidade e estabilidade. Basta selecionar o plano, a forma de pagamento e sair usando! Rápido e sem burocracia.</p>
                      </div>
                      <div><TelecomPlans /></div>
                      <div className=''>
                        <p>Para alterar o seu plano, <span><a href="#">clique aqui</a></span></p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {userData.contracts.length > 0 ? (
                selectedContract && selectedContract.plan && selectedContract.payments && (
                  <div key={selectedContract.contract}>
                    <div>
                      <div>
                        <h1>Pagamentos</h1>
                        <p> Seu plano atual</p>
                        <div>
                          <div>
                            {selectedContract.plan.name};
                          </div>
                          <span className=''></span>
                          <div>
                            <div>
                              <p>Próxima Cobrança:</p>
                              <p>Próximo Vencimento:</p>
                              <p>Forma de Pagamento:</p>
                            </div>
                            <div>
                              {selectedContract.plan.nextCharge}
                              {selectedContract.plan.nextDuoDate}
                              {selectedContract.payments.paymentForm}
                            </div>
                          </div>
                        </div>
                        <p>* Caso altere o meio de pagamento para a cobrança automática, a mesma só será realizada a partir da próxima fatura.</p>
                        <div>
                          <ChangePayentForm />
                        </div>
                      </div>
                    </div>
                    {userData.contracts.length > 0 && selectedContract.payments.invoiceAvailable === true && selectedContract.payments.lateInvoices.length > 0 ? (
                      <div>
                        <div>
                          <p>Você tem uma fatura em atraso!</p>
                          <div>
                            <div>
                              {selectedContract.plan.name}
                            </div>
                            <span className=''></span>
                            <div>
                              <div>
                                <p>Valor:</p>
                                <p>Data de Vencimento:</p>
                                <p>Status do chip:</p>
                              </div>
                              <div>
                                {selectedContract.payments.lateInvoices.map((value: any) => (
                                  <div>
                                    <div>{value.value}</div>
                                    <div>{value.duoDate}</div>
                                  </div>

                                ))}
                                <p>{selectedContract.statusContract}</p>
                              </div>
                            </div>
                          </div>
                          <p>* Regularize suas pendências para continuar utilizando o seu plano e evitar o cancelamento da sua linha.</p>
                          <div>
                            <PayInvoice />
                          </div>
                        </div>
                        
                      </div>
                    ) : (
                      <div>
                        {userData.contracts.length > 0 && selectedContract.payments.invoiceAvailable === true && selectedContract.payments.lateInvoices.length <= 0 ? (
                          <div>
                            <div>
                              <PayInvoice />
                            </div>
                            <div>
                              <p>Não há faturas em atraso, obrigado!</p>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {userData.contracts.length > 0 && selectedContract.payments.invoiceAvailable === false ? (
                              <div>
                                <div>
                                  <p>Não há faturas em atraso, obrigado!</p>
                                </div>
                              </div>
                            ) : (
                              <div>

                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
                ) : (
                <div>
                  {/* Renderize o que você precisa quando contracts.length for 0 */}
                </div>
              )}
              <div className='faq'>
                <div className='faqTitle'>
                  <h2>Perguntas Frequentes</h2>
                  <span className="separadorHorizontal2"></span>
                </div>
                <div id='faq'>
                  <Faq />
                  {/* <span className="separadorHorizontal"></span> */}
                </div>
                <div className='duvidas03'>
                  <p>Tem alguma dúvida sobre nossos planos? entre em contato conosco por <span><a href="#">aqui</a></span>!</p>
                </div>
              </div>
            </div >
          </body >
          <footer>
            <div className='footer'>
              <div className='endereco'>
                <h1>ALFA NETWORK</h1>
                <p>RUA CARLOS SCHUMACKER Nº 314, SALA 01 - JARDIM DOS FRANCOS, SÃO PAULO, BRASIL</p>
                <p>CNPJ: 13.021.557/0001-43</p>
              </div>
              <div>
                <span className="separadorVertical"></span>
              </div>
              <div className='contatos'>
                <div>
                  <span><p>Entre em contato conosco</p></span>
                </div>
                <div>
                  <div>
                    <span><p>Telefones:</p></span>
                    <p>(11) 3983-0314</p>
                    <p>(11) 3983-0314</p>
                    <p>0800 771 03148</p>
                  </div>
                  <div>
                    <div>
                      <span><p>Redes Sociais:</p></span>
                      <div className='socialMedia'>
                        {/* <img src="#" alt="wpp-icon" />
                      <img src="" alt="ig-icon" /> */}
                        <a href="https://www.facebook.com/AlfaTechnologies" rel='noreferrer' target='_blank'><img src={facebook} alt="fb-icon" /></a>
                      </div>
                    </div>
                    <div className='email'>
                      <span><p>E-mail:</p></span>
                      <a href="mailto:contato@internetalfa.com.br">contato@internetalfa.com.br</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='termos'>
              <a href="#" id='termos'>Termos de Uso & Privacidade</a>
              <div className='copy'>
                <p>&copy; Copyright - All Rights Reserved By Alfa Networking | </p>
                <p id='maincode'> Powered By <a href="https://maincode.dev"><img src={maincode} alt="MainCode" /></a> MainCode</p>
              </div>
            </div>
          </footer>
        </main >
      )}
    </>
  );
}

export default Dashboard;