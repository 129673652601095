
import { useState, useContext, useRef, ChangeEvent } from 'react'
import { AuthContext } from '../../../contexts/auth';
import React from 'react';
import { useNavigate } from 'react-router';
import logo from '../../../icons/Component 2.png';
import "./token.css"


function ConfirmToken(): JSX.Element {

    const [login, setUserName] = useState("");
    const { consultEmail, sendToken1, tokenValidation } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const [token, setToken] = useState<string[]>(Array(6).fill(''));
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    const handleChange = (index: number, value: string) => {
        const newToken = [...token];
        newToken[index] = value;
        setToken(newToken);

        if (value !== '' && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();

        } else if (value === '' && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1]?.focus();

        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        const pastedText = event.clipboardData.getData('Text');
        const pastedDigits = pastedText.split('').slice(0, 6);

        const newToken = [...token];
        pastedDigits.forEach((digit, i) => {
            if (newToken[index + i] !== undefined) {
                newToken[index + i] = digit;
            }
        });

        setToken(newToken);
    };

    const handleValidateToken = async (event: any) => {

        event.preventDefault();

        if (!token) {
            setErrorMessage("Por favor, digite o token correto.");
            return;
        }

        let error: any;
        
        try {
            const consult3 = await tokenValidation(token);

            if (consult3 === 200) {
                console.log("token Confirmado");
                navigate("/UserData")
            }

        } catch (event) {
            console.error('Erro ao encontrar e-mail:', error.message);
            setErrorMessage(error.message);
        }

    };

    const handleToken = async (event: any) => {

        let error: any;

        event.preventDefault();

        try {
            const consult2 = await sendToken1(login);

            if (consult2 === 200) {
                console.log("token enviado");
                navigate("/ConfirmToken")
            }

        } catch (event) {
            console.error('Erro ao encontrar e-mail:', error.message);
            setErrorMessage(error.message);
        }

    }

    return (

        <div className="loginContainer">
            <form onSubmit={handleValidateToken} className="formContainer" id='teste'>
                <div className="loginlogo">
                    <img src={logo} alt="logo" className="logo" />
                </div>
                <div >
                    <span className="loginTitle">Corfirme o Token</span>
                </div>
                <div>
                    <p className='loginDescription'>Enviamos um token para confirmar seu e-mail</p>
                </div>
                <div>
                    {errorMessage && <div className="error">{errorMessage}</div>}
                </div>
                <div className="wrapTokenInput">
                    {token.map((digit, index) => (
                        <input className='tokenInput'
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            onPaste={(e) => handlePaste(e, index)}
                            ref={(input) => (inputRefs.current[index] = input)}
                            placeholder='0'
                        />
                    ))}
                </div>
                <div>
                    {errorMessage && <div className="error">{errorMessage}</div>}
                </div>
                <div className="containerResendFormBtn">
                    <button className='resendFormBtn' onClick={handleToken}>Reenviar Token</button>
                </div>
                <div className="containerValidateFormBtn">
                    <button className='validateFormBtn' onClick={handleValidateToken}>Continuar</button>
                </div>
                
            </form>
        </div>
    );
}

export default ConfirmToken
;