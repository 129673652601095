import { useState, useContext } from 'react'
import { AuthContext, getEmail } from '../../../../contexts/auth';
import React from 'react';
import { useNavigate } from 'react-router';
import logo from '../../../../icons/Component 2.png';
import './sendPassword.css'

function SendRecoverPassword(): React.JSX.Element {

    const login = getEmail();
    const [password, setPassword] = useState("");
    const { userLogin, sendRecoverPassword } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {

        navigate("/EnterEmail");

    };

    const handleRecoverPassword = async (event: any) => {

        let error: any;

        event.preventDefault();

        try {
            const response = await sendRecoverPassword(login);
            if (response === 200) {
                console.log("solicitação concluida");
                setMessage("novamente");
                return;
            }
        } catch (event) {
            console.error('Erro no login:', error.message);
            setErrorMessage(error.message);
        }

    }

    return (

        <div className="loginContainer">
            <form onSubmit={handleSubmit} className="formContainer" id='teste'>
                <div className="loginlogo7">
                    <img src={logo} alt="logo" className="logo" />
                </div>
                <div >
                    <span className="loginTitle">Recuperar Senha</span>
                </div>
                <div className='description'>
                    <p>Enviamos {message} um e-mail para você recuperar a sua senha!</p>
                </div>
                <div className="containerLoginFormBtn">
                    <button className='loginFormBtn' onClick={handleSubmit}>Continuar</button>
                </div>
                <div className='containerRegisterFormBtn'>
                    <p>Não recebeu? <button className='registerFormBtn' onClick={handleRecoverPassword}>Reenviar e-mail</button></p>
                </div>
                <div className='obs'>
                    <p>Obs: Confira sua caixa de span antes de solicitar o reenvio!</p>
                </div>
            </form>
        </div>
    )
}

export default SendRecoverPassword;