import React from 'react';
import './ContractTelecomButton.css'

function ContracAdditionalDataButtonCp(): JSX.Element {

    return (
        <>
            <div>
                <button className='contractTelecomButton'>Contratar</button>
            </div>
        </>
    );
}

export default ContracAdditionalDataButtonCp;