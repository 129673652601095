import React from 'react';
import './SeePlans.css'

function SeePlans(): JSX.Element {

    return (
        <>
            <div>
                <button className='contractTelecomButton'>Ver Planos</button>
            </div>
        </>
    );
}

export default SeePlans;