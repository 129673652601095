import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from 'react-router-dom';
import { createSession, recover, register, emailConsult, sendToken2 } from '../services/auth_apis';
import { AuthContextProps, AuthProviderProps } from '../interfaces/authInterfaaces';
import { HttpStatusCode } from "axios";

export const AuthContext = createContext<AuthContextProps | any>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const navigate = useNavigate();

    const [token, setToken] = useState<string | null>("");

    useEffect(() => {

    }, [token]);

    const userLogin = async (login: string, password: string) => {

        console.log(login, password)

        if (login === "1@email.com" && password === "1") {
            const newToken = "teste";
            setToken(newToken);
            saveLogin(newToken, login);
            navigate("/Dashboard");
        }

        // try {
        //     const response = await createSession(login, password);
        //     const newToken = response.data.token;
        //     setToken(newToken);
        //     saveLogin(newToken, login);
        //     navigate("/Dashboard");
        //     return newToken;

        // } catch (error) {
        //     throw error;
        // }
    };

    const userRegister = async (taxNumber: string, email: string, password: string, passwordConfirmation: string) => {

        navigate("/EnterEmail");

        // try {
        //     const response = await registerUser(taxNumber, email, password, passwordConfirmation);
        //     const newToken = response.data.token;
        //     setToken(newToken);
        //     saveLogin(newToken, login);
        //     navigate("/Dashboard");
        //     return newToken;

        // } catch (error) {
        //     throw error;
        // }
    };

    const sendRecoverPassword = async (login: string) => {

        const teste = HttpStatusCode.Ok;
        return teste;

        // try {
        //     const response = await recover(login);
        //     return response.data;
        // } catch (error) {
        //     throw error;
        // }
    }

    const sendToken1 = async (login: string) => {

        const teste = HttpStatusCode.Ok;
        return teste;


        // try {
        //     const response = await sendToken2(login);
        //     return response.data;
        // } catch (error) {
        //     throw error;
        // }
    }

    const tokenValidation = async (token: string) => {

        if (!token) {
            return;
        }

        const teste = HttpStatusCode.Ok;
        return teste;


        // try {
        //     const response = await validateToken(login);
        //     return response.data;
        // } catch (error) {
        //     throw error;
        // }
    }

    const consultEmail = async (login: string) => {

        if (login === "1@email.com") {
            sessionStorage.setItem('login', login);
            navigate("/Login");
        }


        // try {
        //     const response = await emailConsult(login);
        //     return response.data;
        // } catch (error) {
        //     throw error;
        // }
    }

    const confirmUserData = async (taxNumber: string, phoneNumber: string) => {

        if (taxNumber === "44441796862" && phoneNumber === '12996827680') {
            sessionStorage.setItem('taxNumber', taxNumber);
            sessionStorage.setItem('phoneNumber', phoneNumber);
            navigate("/Register");
        }


        // try {
        //     const response = await validateUserData(taxNumber, phoneNumber);
        //     return response.data;
        // } catch (error) {
        //     throw error;
        // }
    }

    function logout() {
        console.log('logout');
        clearData();
        navigate("/Login");
    }

    const login = getEmail() ?? "";


    return (
        <AuthContext.Provider value={{ authenticated: !!login, login, userLogin, userRegister, sendRecoverPassword, confirmUserData, consultEmail, sendToken1, tokenValidation, logout }}>
            {children}
        </AuthContext.Provider>
    )

}

export const getEmail = () => sessionStorage.getItem('login')
export const getToken = () => sessionStorage.getItem('token')

export const saveLogin = (newToken: string, login: string) => {
    sessionStorage.setItem('login', login);
    sessionStorage.setItem('token', newToken);
};

export const clearData = () => {
    sessionStorage.setItem('login', "");
    sessionStorage.setItem('token', "");
    sessionStorage.removeItem('login');
    sessionStorage.removeItem('token');
    sessionStorage.clear();
};
