import React from 'react';
import './ContractInternetButton.css'
import { useNavigate } from 'react-router';

function ContractInternetButton(): JSX.Element {

    const navigate = useNavigate();

    function handleContact (){

        navigate('/Contacts')

    }

    return (
        <>
            <div>
                <button onClick={handleContact} className='contractInternetButton'>Contratar</button>
            </div>
        </>
    );
}

export default ContractInternetButton;