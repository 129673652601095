import React from 'react';
import './ContractInternetButton.css'
import { useNavigate } from 'react-router';


function ContractInternetButtonCp(): JSX.Element {

    const navigate = useNavigate();

    function handleContacts () {
        navigate('/Contacts')
    }

    return (
        <>
            <div>
                <button onClick={handleContacts} className='contractInternetButtonCp'>Contratar</button>
            </div>
        </>
    );
}

export default ContractInternetButtonCp;