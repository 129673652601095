import { useState, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';
import React from 'react';
import { useNavigate } from 'react-router';
import logo from '../../icons/Component 2.png';
import "./register.css"

const cleanNonNumeric = (value: string): string => {
    return value.replace(/\D/g, ''); // Remove caracteres não numéricos
};

const formatCPF = (value: string): string => {
    const cleanedValue = cleanNonNumeric(value);
    const truncatedValue = cleanedValue.slice(0, 11); // Limita para 11 caracteres (CPF)
    const match = truncatedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
    return match
        ? `${match[1] ? `${match[1]}.` : ''}${match[2] ? `${match[2]}.` : ''}${match[3] ? `${match[3]}-` : ''}${match[4]}`
        : '';
};

const formatPhoneNumber = (value: string): string => {
    const cleanedValue = cleanNonNumeric(value);
    const truncatedValue = cleanedValue.slice(0, 11);
    const match = truncatedValue.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);
    return match ? `${match[1] ? `(${match[1]}` : ''}${match[2] ? `) ${match[2]}` : ''}${match[2] && match[3] ? '-' : ''}${match[3]}` : '';
};


function UserData(): React.JSX.Element {


    const { confirmUserData } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const [taxNumber, setCPF] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleTaxNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCPF(cleanNonNumeric(e.target.value));
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(cleanNonNumeric(e.target.value));
    };

    const handleConfirmUserData = async (event: any) => {

        let error: any;

        event.preventDefault();

        console.log(taxNumber, phoneNumber)

        try {
            const consult4 = await confirmUserData(taxNumber, phoneNumber);


        } catch (event) {
            console.error('Erro ao encontrar e-mail:', error.message);
            setErrorMessage(error.message);
        }

    };

    return (

        <div className="loginContainer">
            <form onSubmit={handleConfirmUserData} className="formContainer" id='teste'>
                <div className="loginlogo">
                    <img src={logo} alt="logo" className="logo" />
                </div>
                <div >
                    <span className="loginTitle">Preencha seus dados</span>
                </div>
                <div className='inputTitle'>
                    <p>CPF <span>(somente números)</span></p>
                </div>
                <div>
                    {errorMessage && <div className="error">{errorMessage}</div>}
                </div>
                <div className="wrapInput">
                    <input
                        type="text"
                        className='input'
                        value={formatCPF(taxNumber)}
                        onChange={handleTaxNumberChange}
                        placeholder='Digite seu CPF (xxx.xxx.xxx-xx)'
                        required
                    />
                </div>
                <div className='inputTitle'>
                    <p>Telefone <span>(número Alfa com DDD)</span></p>
                </div>
                <div className="wrapInput">
                    <input
                        type="text"
                        className='input'
                        placeholder='Digite seu número ((xx)xxxxx-xxxx)'
                        value={formatPhoneNumber(phoneNumber)}
                        onChange={handlePhoneNumberChange}
                        required
                    />
                </div>
                <div className='advise'>
                    <p>* Adicione o seu número principal. Se você possuí mais de um número, poderá adicioná-los depois</p>
                </div>
                <div className="containerLoginFormBtn">
                    <button className='loginFormBtn' onClick={handleConfirmUserData}>Continuar</button>
                </div>
                
            </form>
        </div>
    )
}

export default UserData;