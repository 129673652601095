import React, { createContext } from "react";
import {balance, plans, additionalDataPlans, contracts } from '../services/service_apis';
import { ServiceContextProps, ConsultProviderProps } from '../interfaces/serviceInterfaces';

export const ServiceContext = createContext<ServiceContextProps | any>(undefined);

export const ServiceProvider: React.FC<ConsultProviderProps> =({ children }) => {
    
    const consultBalance = async (contract:string) => {
        try {
            const response = await balance(contract);
            const message = response.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar registrar o score, tente novamente.");
        }
    }

    const consultPlans = async (contract:string) => {
        try {
            const response = await plans(contract);
            const message = response.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar registrar o score, tente novamente.");
        }
    }

    const consultAdditionalDataPlans = async (contract:string) => {
        try {
            console.log("chegou aqui 02")
            const response02 = await additionalDataPlans(contract);
            const message = response02.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar consultar as análises, tente novamente. ");
            
        }
    }    

    const consultContract = async() => {
        try {
            console.log("chegou aqui 02")
            const response02 = await contracts();
            const message = response02.data.message
            console.log(message);
        } catch (error) {
            console.error(error);
            throw new Error("Ocorreu um erro ao tentar consultar as análises, tente novamente. ");

        }
    }    

    return (
        <ServiceContext.Provider value={{ consultContract, consultPlans, consultAdditionalDataPlans, consultBalance }}>
            {children}
        </ServiceContext.Provider>
    )
}
