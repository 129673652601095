import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/auth';
import { useNavigate } from 'react-router';
import logo from '../../icons/Component 2.png'
import "./password.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'

const Register: React.FC = () => {

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);
    const { userRegister } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const taxNumber = sessionStorage.getItem('taxNumber');
    const email = sessionStorage.getItem('login');
    const navigate = useNavigate();

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;

        setPassword(newPassword);
        setPasswordMatch(newPassword === passwordConfirmation);
        setHasUpperCase(/[A-Z]/.test(newPassword));
        setHasNumber(/\d/.test(newPassword));
        setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword));
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConfirmPassword = e.target.value;

        setConfirmPassword(newConfirmPassword);
        setPasswordMatch(password === newConfirmPassword);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (event: any) => {

        let error: any;

        event.preventDefault();

        try {

            const register = await userRegister(taxNumber, email, password, passwordConfirmation);

        } catch (event) {
            console.error('Erro no login:', error.message);
            setErrorMessage(error.message);
        }

    };

    return (
        <div>
            <div className="loginContainer">
                <form onSubmit={handleSubmit} className="formContainer" id='teste'>
                    <div className="loginlogo2">
                        <img src={logo} alt="logo" className="logo" />
                    </div>
                    <div className='inputTitle'>
                        <p>Crie sua senha de acesso</p>
                    </div>
                    <div>
                        {errorMessage && <div className="error">{errorMessage}</div>}
                    </div>
                    <div className="wrapInput">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            className={password !== "" ? `${"hasVal"} ${"input"}` : `${"input"}`}
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Digite sua nova senha"
                            required
                        />
                        <div className="password-toggle" onClick={toggleShowPassword}>
                            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} size="2x" style={{ color: "#283a5b", }} /> : <FontAwesomeIcon icon={faEye} size="2x" style={{ color: "#283a5b", }} />}
                        </div>
                    </div>
                    <div className='inputTitle'>
                        <p>Confirme sua senha</p>
                    </div>
                    <div className="wrapInput">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            className={passwordConfirmation !== "" ? `${"hasVal"} ${"input"}` : `${"input"}`}
                            value={passwordConfirmation}
                            onChange={handleConfirmPasswordChange}
                            placeholder="Digite novamente a senha"
                            required
                        />
                        <div className="password-toggle" onClick={toggleShowConfirmPassword}>
                            {showConfirmPassword ? <FontAwesomeIcon icon={faEyeSlash} size="2x" style={{ color: "#283a5b", }} /> : <FontAwesomeIcon icon={faEye} size="2x" style={{ color: "#283a5b", }} />}
                        </div>
                    </div>
                    {passwordMatch ? null : <div className="error">As senhas não coincidem</div>}
                    <div className='passwordDirection'>
                        <div className='passwordDirection1'>
                            <p>Sua senha deve conter ao menos:</p>
                        </div>
                        <div className='passwordDirection3'>
                            <p className='passwordDirection2'>{hasUpperCase ? <span style={{ color: 'green', marginRight: '5px' }}>✔</span> : <span style={{ color: 'red', marginRight: '5px' }}>✖</span>} 1 Letra Maiúscula (Exemplo: A,B,C,D...)</p>
                            <p className='passwordDirection2'>{hasNumber ? <span style={{ color: 'green', marginRight: '5px' }}>✔</span> : <span style={{ color: 'red', marginRight: '5px' }}>✖</span>} 1 Número (Exemplo: 1,2,3,4...)</p>
                            <p className='passwordDirection2'>{hasSpecialChar ? <span style={{ color: 'green', marginRight: '5px' }}>✔</span> : <span style={{ color: 'red', marginRight: '5px' }}>✖</span>} 1 Caractere Especial (Exemplo: @#./-_)</p>
                            <p className='passwordDirection2'>{password.length >= 8 ? <span style={{ color: 'green' }}>✔</span> : <span style={{ color: 'red' }}>✖</span>}8 Caracteres (Exemplo: A1b2C@d#)</p>
                        </div>
                    </div>

                    <div className="containerLoginFormBtn">
                        <button className='loginFormBtn' onClick={handleSubmit}>Continuar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
