import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://analytics.sinapsys.ai'
});

export const balance = async (contract:string) => {
  const data = { contract };
  return api.post("/api/v1/dashboard/registerScore", { data} )
}

export const plans = async (contract: string) => {
  const data = { contract };
  return api.put("/api/v1/dashboard/changeScore", { data} )
}

export const additionalDataPlans = async (contract: string) => {
  const data = { contract };
  return api.post("/api/v1/dashboard/consultAnalysisByDate", data);
}

export const contracts = async () => {
  
  return api.get("/api/v1/dashboard/consultAnalysisByPartnerId")
}