import { useState, useContext } from 'react'
import { AuthContext } from '../../contexts/auth';
import React from 'react';
import './login.css'
import { useNavigate } from 'react-router';
import logo from '../../icons/Component 2.png';

function EnterEmail(): React.JSX.Element {

  const [login, setUserName] = useState("");
  const { consultEmail, sendToken1 } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleConsultEmail = async (event: any) => {

    event.preventDefault();

    if (!login) {
      setErrorMessage("Por favor, digite seu e-mail.");
      return;
    }

    let error: any;

    try {
      const consult = await consultEmail(login);

    } catch (event) {
      console.error('Erro ao encontrar e-mail:', error.message);
      setErrorMessage(error.message);
    }

  };

  const handleToken = async (event: any) => {

    event.preventDefault();

    if (!login) {
      setErrorMessage("Para se cadastrar, digite seu e-mail.");
      return;
    }

    let error: any;

    try {
      const consult2 = await sendToken1(login);

      if (consult2 === 200) {
        console.log("token enviado");
        navigate("/ConfirmToken")
      }

    } catch (event) {
      console.error('Erro ao encontrar e-mail:', error.message);
      setErrorMessage(error.message);
    }

  }

  return (

    <div className="loginContainer">
      <form onSubmit={handleConsultEmail || handleToken} className="formContainer" id='teste'>
        <div className="loginlogo">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div >
          <span className="loginTitle">Faça seu login</span>
        </div>
        <div className='wrap'>
          <div className="wrapInput5">
            <input type="email" placeholder='Digite seu e-mail' className='input' value={login} onChange={e => setUserName(e.target.value)} />
          </div>
          <div>
            {errorMessage && <div className="error">{errorMessage}</div>}
          </div>
        </div>
        <div className="containerLoginFormBtn">
          <button className='loginFormBtn' onClick={handleConsultEmail}>Continuar</button>
        </div>
        <div className="containerRegisterFormBtn">
          <p>Primeira vez aqui? <button className='registerFormBtn' onClick={handleToken}>Crie seu acesso</button></p>
        </div>
      </form>
    </div>
  )
}

export default EnterEmail;