import React from 'react';
import './ContractInternetButton.css'


function PayInvoice(): JSX.Element {

    return (
        <>
        </>
    );
}

export default PayInvoice;