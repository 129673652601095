import React from 'react';
import './ContractInternetButton.css'


function ChangePayentForm(): JSX.Element {

    return (
        <>
        </>
    );
}

export default ChangePayentForm;